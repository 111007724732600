import { Text, Box, Container, Heading, Center } from "@chakra-ui/react";

export function About() {
  return (
    <Container maxW="xl" py={"20vh"}>
      <Container>
        <Box bg="white" p={20} boxShadow="md" borderRadius="md" color="#333">
          <Heading as="h2" size="lg" mb={6} color="teal.500" textAlign="center">
            Welcome to modulu.ch!
          </Heading>

          <Text fontSize="md" textAlign="center" maxW="sm">
            We are a group of students from ETH Zürich in Switzerland. In the
            context of the student project house, we are developing this data
            management portal and platform.
          </Text>
        </Box>
      </Container>

      {/* <Container maxW="md" py={16} position="relative" textAlign="center">
        <div>
          <Heading as="h2" size="xl" mb={6} color="teal.500">
            Welcome to modulu.ch!
          </Heading>

          <Text fontSize="md" textAlign="center" maxW="sm">
            We are a group of students from ETH Zürich in Switzerland. In the
            context of the student project house, we are developing this data
            management portal and platform.
            <br />
          </Text>
        </div>
      </Container> */}
    </Container>
  );
}
