import {
  Box,
  RadioGroup,
  Text,
  Container,
  VStack,
  FormControl,
  HStack,
  Radio,
  Select,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export function SearchListings() {
  const navigate = useNavigate();

  return (
    <Container maxW="md" py={60} flexGrow="1">
      {/* <Box bg="#f5f5f5" py={50}> */}
      <Box bg="#fff" p={8} boxShadow="md" borderRadius="md">
        <Text
          as="h2"
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          color="#333"
          marginBottom={"10px"}
        >
          Search Properties
        </Text>

        <Formik
          initialValues={{
            name: "",
            zipCode: "",
            type: "rent",
          }}
          onSubmit={(values, actions) => {
            const params = "?" + new URLSearchParams(values);
            navigate("/results" + params);
          }}
        >
          {(props) => (
            <Form>
              <VStack spacing={4}>
                <FormControl>
                  <RadioGroup
                    marginBottom="10px"
                    defaultValue="rent"
                    color="#555"
                  >
                    <HStack justifyContent="center" spacing="50px">
                      <Radio value="rent" borderColor="#555" colorScheme="teal">
                        Rent
                      </Radio>
                      <Radio value="buy" borderColor="#555" colorScheme="teal">
                        Buy
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <Select
                    id="zipCode"
                    marginBottom="10px"
                    placeholder="Select Zip Code"
                    _placeholder={{ color: "#999" }}
                    onChange={props.handleChange}
                    value={props.values.zipCode}
                    color="#333"
                    bg="#f5f5f5"
                    borderColor="#ddd"
                  >
                    <option value="8001">8001</option>
                    <option value="8002">8002</option>
                    <option value="8003">8003</option>
                    <option value="8004">8004</option>
                    <option value="8005">8005</option>
                    <option value="8006">8006</option>
                    <option value="8007">8007</option>
                    <option value="8008">8008</option>
                    <option value="8009">8009</option>
                    <option value="8049">8048</option>
                    <option value="8050">8050</option>
                    <option value="8051">8051</option>
                  </Select>
                </FormControl>

                <Button type="submit" colorScheme="teal" width="100%">
                  Submit
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
      {/* </Box> */}
    </Container>
  );
}
