import { useState, useEffect } from "react";
import { Flex, Text, Box, Container, Spinner } from "@chakra-ui/react";
import { Property } from "../components/Property";
import { useLocation } from "react-router-dom";

export function ResultsAssets() {
  const location = useLocation();
  const data = location.state;
  const limit = 6;
  const [page, setPage] = useState(0);

  const [results, setResults] = useState({
    nbHits: 0,
    hits: Array(limit).fill("Loading"),
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const offSet = page * limit;
    setIsLoading(false);
    setResults(data);
    console.log("results", results);
  }, [data]);

  return isLoading ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      color="blackAlpha.100"
    >
      <Spinner size="xl" color="teal.500" />
    </Flex>
  ) : (
    <Container maxW="container.lg" py={10} color="blackAlpha.100">
      <Text color="white" fontSize="xl" mb={4}>
        The number of results is 1.
      </Text>
      <Flex flexDirection="column" alignItems="center">
        <Property
          marginBottom="10px"
          key={results.id}
          property={results}
          Details={`assets/${results.id}`}
        />
      </Flex>
      <Box marginBottom="50px" />
    </Container>
  );
}
