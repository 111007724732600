import { Box, Text } from "@chakra-ui/react";

function Footer(props) {
  return (
    <Box
      as="footer"
      position="absolute"
      left="0"
      right="0"
      bottom="0"
      padding="10px 0"
      backgroundColor="#333"
      textAlign="center"
      {...props}
    >
      <Text fontSize="sm" color="white">
        &copy; 2022 modulu. All rights reserved. <br></br>
        <span>info@modulu.ch</span>{" "}
      </Text>
    </Box>
  );
}

export default Footer;
