import {
  Box,
  RadioGroup,
  Text,
  Input,
  Container,
  AbsoluteCenter,
  FormControl,
  HStack,
  Radio,
  Select,
} from "@chakra-ui/react";
import { useApi } from "../contexts/ApiProvider";
import { Formik, Form, ErrorMessage } from "formik";
import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function SearchAssets() {
  const api = useApi();
  const navigate = useNavigate();
  const [authorization, setAuthorization] = useState("Bearer undefined");

  // Validation function to check if any of the fields are empty
  const validateForm = (values) => {
    const errors = {};
    if (!values.city) {
      errors.city = "City is required";
    }
    if (!values.street) {
      errors.street = "Street is required";
    }
    if (!values.street_nr) {
      errors.street_nr = "Street number is required";
    }
    if (!values.zip_code) {
      errors.zip_code = "Zip Code is required";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    const { city, street, street_nr, zip_code } = values;

    const url = `/assets/search/${street}/${street_nr}/${zip_code}/${city}`;

    const response = await api.get(url, {
      headers: {
        Accept: "application/json",
        Authorization: authorization,
      },
    });
    console.log(response.body);

    const data = response.body;

    try {
      const response = await api.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: authorization,
        },
      });

      if (response.body.code && response.body.message) {
        // API returned an error response
        console.log("Error response", response.body);
        alert(response.body.message);
      } else {
        // API returned successful data
        console.log("Data", response.body);
        navigate("/results/assets", { state: response.body });
      }
    } catch (error) {
      console.error("Error occurred:", error);
      alert("An error occurred while fetching the data.");
    }
  };

  return (
    <Container maxW="md" py={60} flexGrow="1" position="relative">
      <Box bg="#fff" p={8} boxShadow="md" borderRadius="md">
        <Text
          as="h2"
          fontSize="2xl"
          fontWeight="bold"
          mb={4}
          textAlign="center"
          color="#333"
        >
          Search Properties
        </Text>

        <Formik
          initialValues={{
            city: "",
            street: "",
            street_nr: "",
            zip_code: "",
          }}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {(props) => (
            <Form>
              <FormControl>
                <Input
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  onChange={props.handleChange}
                  value={props.values.city}
                  bg="#f5f5f5"
                  _placeholder={{ color: "#999" }}
                  color="#333"
                  focusBorderColor="teal.500"
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  style={{ color: "red" }}
                />
                <Input
                  type="text"
                  name="street"
                  placeholder="Enter Street"
                  onChange={props.handleChange}
                  value={props.values.street}
                  bg="#f5f5f5"
                  _placeholder={{ color: "#999" }}
                  color="#333"
                  focusBorderColor="teal.500"
                  mt={4}
                />
                <ErrorMessage
                  name="street"
                  component="div"
                  style={{ color: "red" }}
                />
                <Input
                  type="text"
                  name="street_nr"
                  placeholder="Enter Number"
                  onChange={props.handleChange}
                  value={props.values.street_nr}
                  bg="#f5f5f5"
                  _placeholder={{ color: "#999" }}
                  color="#333"
                  focusBorderColor="teal.500"
                  mt={4}
                />
                <ErrorMessage
                  name="street_nr"
                  component="div"
                  style={{ color: "red" }}
                />
                <Input
                  type="text"
                  name="zip_code"
                  placeholder="Enter Zip Code"
                  onChange={props.handleChange}
                  value={props.values.zip_code}
                  bg="#f5f5f5"
                  _placeholder={{ color: "#999" }}
                  color="#333"
                  focusBorderColor="teal.500"
                  mt={4}
                />
                <ErrorMessage
                  name="zip_code"
                  component="div"
                  style={{ color: "red" }}
                />
              </FormControl>

              <Button type="submit" colorScheme="teal" width="100%" mt={4}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
}
