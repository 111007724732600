import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MapContainer } from "../components/MapContainer";
import {
  SimpleGrid,
  Box,
  Flex,
  Text,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { BsHouseFill } from "react-icons/bs";

export function PortfolioDetail() {
  const { state } = useLocation();
  const portfolio = state;

  // State to store the geocoded markers
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    // Function to geocode an address using Google Geocoding API
    const geocodeAddress = async (address) => {
      try {
        const apiKey = "AIzaSyBGZJbqbaokie6irvz30BNHSUAFbYUUvOE";
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();
        console.log(data);

        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          return { lat, lng };
        } else {
          return null; // Address not found, handle the error accordingly
        }
      } catch (error) {
        console.error("Error geocoding address:", error);
        return null;
      }
    };

    // Geocode all the addresses and set the markers state
    const geocodeAllAddresses = async () => {
      const geocodedMarkers = await Promise.all(
        portfolio.tracked.map(async (asset) => {
          const { street, street_nr, city, zip_code } = asset;
          const address = `${street},${street_nr}, ${city}, ${zip_code}`;
          const coordinates = await geocodeAddress(address);
          return coordinates;
        })
      );

      setMarkers(geocodedMarkers.filter((marker) => marker !== null));
    };

    geocodeAllAddresses();
  }, [portfolio]);

  return (
    <Flex flexDirection="column" flexGrow="1" p={105} py={40}>
      {/* TITLE */}
      <Flex flexDir="column" align="center">
        <Heading textAlign="center" as="h1" size="lg" mb={2}>
          {portfolio.name.toUpperCase()} PORTFOLIO
        </Heading>
      </Flex>

      <Divider mb={6} />
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={6}>
        {/* LEFT COLUMN - MAP */}

        <Box
          height="400px"
          borderRadius="md"
          overflow="hidden"
          boxShadow="base"
          p="3"
        >
          <MapContainer markers={markers} />
        </Box>
        {/* RIGHT COLUMN - LIST OF PROPERTIES */}

        <Box
          height="400px"
          borderRadius="md"
          p="3"
          boxShadow="base"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              borderRadius: "xl",
              backgroundColor: "gray.200",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "xl",
              backgroundColor: "gray",
            },
          }}
        >
          {/* <BsHouseFill fontSize="26px" color="teal.500" mb={2} /> */}
          <Text
            fontSize="lg"
            fontWeight="bold"
            color="gray.600"
            mb={4}
            align="center"
          >
            {portfolio.tracked.length} Assets
          </Text>
          {portfolio.tracked.map((asset, index) => (
            <Box
              key={index}
              p={1}
              py={2}
              borderRadius="md"
              bg="white"
              mb={4}
              boxShadow="md"
            >
              <Flex align="center" mb={2}>
                <BsHouseFill fontSize="24px" color="teal" />
                <Text fontSize="sm" color="gray.800" ml={2}>
                  {asset.street} {asset.street_nr}, {asset.city},{" "}
                  {asset.zip_code}
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </SimpleGrid>
    </Flex>
  );
}
