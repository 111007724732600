import * as React from "react";
import { Box, Text, HStack, Link } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { PaginateProps } from "./types/paginate.d";

export const Paginate = (props: PaginateProps) => {
  const {
    count,
    pageSize,
    page,
    onPageChange,
    margin = 1,
    colorScheme = "teal.500", // Use a primary color here (e.g., teal)
    secondaryColor = "gray", // Use a secondary color here (e.g., gray)
    ...rest
  } = props;

  const numberOfPages = Math.ceil(count / pageSize);

  const handlePageClick = (i: number) =>
    onPageChange(Math.min(numberOfPages - 1, Math.max(0, i)));

  const shouldRender = (idx: number) =>
    idx === page ||
    Math.abs(idx - page) <= margin ||
    idx === numberOfPages - 1 ||
    idx === 0;

  const shouldRenderEllipsis = (idx: number) =>
    idx === page || Math.abs(idx - page) === margin + 1;

  return (
    <HStack spacing={4} mt={4}>
      <Link
        onClick={() => handlePageClick(page - 1)}
        isDisabled={page === 0}
        color={secondaryColor} // Use the secondary color for inactive buttons
        _hover={{ textDecoration: "underline" }} // Add underline on hover
      >
        <ChevronLeftIcon /> Prev
      </Link>
      {Array.from({ length: numberOfPages }, (_, i) => {
        return shouldRender(i) ? (
          <Box
            key={i}
            color={i === page ? "white" : secondaryColor} // Use white color for the active button
            bg={i === page ? colorScheme : "transparent"} // Use the primary color for the active button's background
            borderRadius="md" // Add rounded corners to buttons
            px={2} // Add horizontal padding to buttons
            py={1} // Add vertical padding to buttons
            _hover={{
              textDecoration: "underline", // Add underline on hover
              bg: secondaryColor, // Use the secondary color for hover background
              color: "white", // Use white color on hover
            }}
          >
            <Link onClick={() => handlePageClick(i)}>{i + 1}</Link>
          </Box>
        ) : shouldRenderEllipsis(i) ? (
          <Text color={secondaryColor}>...</Text>
        ) : null;
      })}
      <Link
        onClick={() => handlePageClick(page + 1)}
        isDisabled={page === numberOfPages - 1}
        color={secondaryColor} // Use the secondary color for inactive buttons
        _hover={{ textDecoration: "underline" }} // Add underline on hover
      >
        Next <ChevronRightIcon />
      </Link>
    </HStack>
  );
};
