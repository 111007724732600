import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Collapse,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useUser } from "../contexts/UserProvider";

const NavLink = ({ children, to, onToggle = null }) => (
  <Button
    as={Link}
    to={to}
    variant="ghost"
    color="white"
    _hover={{ color: "gray.400" }}
    mx={2}
    onClick={() => {
      if (onToggle != null) {
        onToggle(); // Closes the navbar
      }
      window.scrollTo(0, 0); // Scrolls the page to the top
    }}
  >
    {children}
  </Button>
);
export function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const navBgColor = "#333";
  const textColor = "white";
  const isSmallScreen = window.innerWidth <= 768;

  // const handleLogout = () => {
  //   logout();
  //   navigate("/");
  //   if (isSmallScreen) {
  //     onToggle();
  //   }
  // };

  return (
    <Box
      as="nav"
      bg={navBgColor}
      py={3}
      boxShadow="md"
      position="fixed"
      width="100%"
      top={0}
      zIndex={999}
      color={textColor}
    >
      <Flex
        maxW={{
          base: "container.sm",
          md: "container.md",
          lg: "container.lg",
          xl: "container.xl",
        }}
        mx="auto"
        px={4}
        align="center"
        justify="space-between"
      >
        <Flex align="center">
          <Text fontWeight="bold" fontSize="lg" color={textColor}>
            <Link
              to="/"
              _hover={{ textDecoration: "none" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              modulu
            </Link>
          </Text>
        </Flex>

        <Flex display={{ base: "none", md: "flex" }} align="center">
          {user ? (
            <>
              {!isSmallScreen && (
                <>
                  <NavLink to="/search/assets">Assets</NavLink>
                  <NavLink to="/search/listings">Listings</NavLink>
                  <NavLink to="/portfolios">Portfolios</NavLink>
                </>
              )}
              <IconButton
                aria-label={isOpen ? "Close menu" : "Open menu"}
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                onClick={onToggle}
                variant="ghost"
                color="white"
                mx={2}
              />
            </>
          ) : (
            <>
              <NavLink to="/signup">Sign up</NavLink>
              <NavLink to="/login">Login</NavLink>
            </>
          )}
        </Flex>

        <IconButton
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={isOpen ? "Close menu" : "Open menu"}
          display={{ base: "flex", md: "none" }}
          onClick={onToggle}
          variant="ghost"
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box py={2} bg={navBgColor}>
          <Flex direction="column" align="center">
            {user ? (
              <>
                {isSmallScreen && (
                  <>
                    <NavLink to="/search/assets" onToggle={onToggle}>
                      Assets
                    </NavLink>
                    <NavLink to="/search/listings" onToggle={onToggle}>
                      Listings
                    </NavLink>
                    <NavLink to="/portfolios" onToggle={onToggle}>
                      Portfolios
                    </NavLink>
                  </>
                )}
                <NavLink to="/about" onToggle={onToggle}>
                  About
                </NavLink>
                <Button
                  onClick={() => {
                    logout();
                    navigate("/");
                    onToggle();
                  }}
                  variant="ghost"
                  color="white"
                  _hover={{ color: "gray.900" }}
                  mx={2}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <NavLink to="/about" onToggle={onToggle}>
                  About
                </NavLink>
                <NavLink to="/signup" onToggle={onToggle}>
                  Sign up
                </NavLink>
                <NavLink to="/login" onToggle={onToggle}>
                  Login
                </NavLink>
              </>
            )}
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
}
