import React, { useState, useEffect } from "react";

import { useApi } from "../contexts/ApiProvider";

import {
  Flex,
  Box,
  Container,
  AbsoluteCenter,
  Text,
  Button,
} from "@chakra-ui/react";
import { FormLabel, FormControl, InputGroup, Input } from "@chakra-ui/react";
import { List, ListItem, ListIcon } from "@chakra-ui/react";
import { MdCheckCircle, MdSettings } from "react-icons/md";

import { Form, Formik, Field, FieldArray } from "formik";

import { HiPlus, HiMinus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export function CreatePortfolio() {
  const api = useApi();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    addresses: [""],
  };
  const onSubmit = async (values, actions) => {
    const name = values.name;
    const addresses = values.addresses;

    console.log(values);

    var isArr = Object.prototype.toString.call(addresses) === "[object Array]";
    if (typeof name != typeof "" || !isArr) {
      // do stuff
      console.log("Incorrect form input types. No server call.");
    } else {
      const data = await api.put("/portfolios", {
        name: name,
        addresses: addresses,
      });
      if (!data.ok) {
        // trigger form error
        console.log(data);
        console.log("data.ok is false, did not sign up");
      } else {
        // clear form errors
        console.log("data.ok is true, submitted sign up to server");
        navigate("/portfolios");
      }
    }
  };

  return (
    <Container maxW="md" py={60}>
      <Box bg="#fff" p={8} boxShadow="md" borderRadius="md">
        <Text
          as="h2"
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          color="#333"
          mb={4}
        >
          Create Portfolio
        </Text>
        <Flex
          justifyContent="center"
          flexDirection="column"
          // padding="5vh"
          color="#333"
          // width="40vw"
          // borderRadius="10px"
        >
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(values, errors) => (
              <Form autoComplete="off">
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    id="name"
                    type="name"
                    bg="#f5f5f5"
                    _placeholder={{ color: "#999" }}
                    color="#333"
                    focusBorderColor="teal.500"
                    mb={4}
                    value={values.name}
                    onChange={values.handleChange}
                  />
                </FormControl>

                <FormLabel>Addresses</FormLabel>
                <FieldArray name="addresses">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    return (
                      <div>
                        {values.addresses.map((address, index) => {
                          return (
                            <FormControl key={index}>
                              <Flex>
                                <Field name={`addresses[${index}]`}>
                                  {(props) => {
                                    return (
                                      <Input
                                        placeholder="Example street 1, 8008 Zurich"
                                        value={props.field.value}
                                        id={`addresses[${index}]`}
                                        name={`addresses[${index}]`}
                                        onChange={props.field.onChange}
                                        bg="#f5f5f5"
                                        _placeholder={{ color: "#999" }}
                                        color="#333"
                                        focusBorderColor="teal.500"
                                        mb={4}
                                      />
                                    );
                                  }}
                                </Field>
                                <Button
                                  color="black"
                                  bg="#f5f5f5"
                                  size="sm"
                                  m={2}
                                  border="1px"
                                  borderColor="teal.500"
                                  onClick={() => push("")}
                                >
                                  <HiPlus />
                                </Button>
                                <Button
                                  color="#333"
                                  bg="#f5f5f5"
                                  // make the border color non transparent

                                  border="1px"
                                  borderColor="teal.500"
                                  size="sm"
                                  m={2}
                                  onClick={() => remove(index)}
                                >
                                  <HiMinus />
                                </Button>
                              </Flex>
                            </FormControl>
                          );
                        })}
                      </div>
                    );
                  }}
                </FieldArray>

                <Button colorScheme="teal" width="100%" mt={4} type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
      </Box>
    </Container>
  );
}

/* 
<Text fontSize="calc(10px + 2vmin)">
                Flask says the current time is .
            </Text>
            <InputGroup>
                <Input placeholder='medium size' size='md' />
                <Button type='submit'>Add</Button>
            </InputGroup>




<Grid templateColumns="repeat(3, 1fr)">
                  <FormControl>
                    <GridItem>
                      <FormLabel>
                        first name
                      </FormLabel>
                      <Field
                      name="firstName"
                      value={props.firstName}
                      onChange={props.handleChange}
                      placeholder="Type">
                      </Field>
                    </GridItem>
                  </FormControl>
                  <FormControl>
                    <GridItem>
                      <FormLabel>
                        middle name
                      </FormLabel>
                      <Field
                      name="middleName"
                      value={props.middleName}
                      onChange={props.handleChange}
                      placeholder="Type">
                      </Field>
                    </GridItem>
                  </FormControl>
                  <FormControl>
                    <GridItem>
                      <FormLabel>
                        last name
                      </FormLabel>
                      <Field
                      name="lastName"
                      value={props.lastName}
                      onChange={props.handleChange}
                      placeholder="Type">
                      </Field>
                    </GridItem>
                  </FormControl>
                </Grid>
*/

export function CreatePortfolio2() {
  const [currentTime, setCurrentTime] = useState(1);
  const api = useApi();

  useEffect(() => {
    (async () => {
      const response = await api.get("/time");
      if (response.ok) {
        //then(res => res.json()).then(data => {setCurrentTime(data.time);
        setCurrentTime(response.body.time);
      } else {
        setCurrentTime(null);
      }
    })();
  }, [api]);
  return (
    <Container flexGrow="1">
      <AbsoluteCenter>
        <Box>
          <Text fontSize="calc(10px + 2vmin)">
            Flask says the current time is {currentTime}.
          </Text>
          <InputGroup>
            <Input placeholder="medium size" size="md" />
            <Button type="submit">Add</Button>
          </InputGroup>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Assumenda, quia temporibus eveniet a libero incidunt suscipit
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
            </ListItem>
            {/* You can also use custom icons from react-icons */}
            <ListItem>
              <ListIcon as={MdSettings} color="green.500" />
              Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
            </ListItem>
          </List>
          <Button type="submit">Create Portfolio</Button>
        </Box>
      </AbsoluteCenter>
    </Container>
  );
}
