import { Flex, Text, Box, Heading, IconButton } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { FiSearch, FiEdit, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";

export function PortfolioWidget({ portfolio }) {
  console.log(portfolio);
  return (
    <Card width="200px" height="200px" bg="#fff" color="#333">
      <CardHeader>
        <Flex>
          <Box>
            <Heading size="sm">{portfolio.name}</Heading>
          </Box>
        </Flex>
      </CardHeader>
      <CardBody>
        <Text>{portfolio.tracked.length} Buildings</Text>
      </CardBody>
      <CardFooter justify="space-between">
        <Link to={`/portfolio/detail/${portfolio.id}`} state={portfolio}>
          <IconButton
            aria-label="Search database"
            icon={<FiSearch />}
            color="#333"
          />
        </Link>
        <IconButton
          aria-label="Edit portfolio"
          icon={<FiEdit />}
          color="#333"
        />
        <IconButton
          aria-label="Delete portfolio"
          icon={<FiTrash />}
          color="#333"
        />
      </CardFooter>
    </Card>
  );
}
