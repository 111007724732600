import {
  Button,
  Input,
  FormControl,
  Container,
  AbsoluteCenter,
  Heading,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useApi } from "../contexts/ApiProvider";

export function SignUp() {
  const navigate = useNavigate();
  const api = useApi();

  const formik = useFormik({
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordRep: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = "Username is required";
      }

      if (!values.firstName) {
        errors.firstName = "First Name is required";
      }

      if (!values.lastName) {
        errors.lastName = "Last Name is required";
      }

      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (!values.password) {
        errors.password = "Password is required";
      }

      if (!values.passwordRep) {
        errors.passwordRep = "Repeat Password is required";
      } else if (values.password !== values.passwordRep) {
        errors.passwordRep = "Passwords must match";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const { username, password, passwordRep, email } = values;

      if (password !== passwordRep) {
        // TODO: handle password mismatch
      } else {
        const data = await api.post("/users", {
          username: username,
          password: password,
          email: email,
        });
        if (!data.ok) {
          // TODO: handle form submission error
          console.log(data);
          console.log("data.ok is false, did not sign up");
        } else {
          // Clear form errors and navigate to homepage
          navigate("/");
        }
      }
    },
  });

  return (
    <Container maxW="md" py={40} position={"relative"}>
      <Box bg="#fff" p={8} boxShadow="md" borderRadius="md">
        <Heading as="h2" size="lg" mb={6} textAlign="center">
          Sign Up
        </Heading>

        <form onSubmit={formik.handleSubmit}>
          <FormControl
            id="username"
            isInvalid={formik.touched.username && formik.errors.username}
            mb={4}
          >
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Username"
              onChange={formik.handleChange}
              value={formik.values.username}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.username}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="password"
            isInvalid={formik.touched.password && formik.errors.password}
            mb={4}
          >
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.password}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="passwordRep"
            isInvalid={formik.touched.passwordRep && formik.errors.passwordRep}
            mb={4}
          >
            <Input
              id="passwordRep"
              name="passwordRep"
              type="password"
              placeholder="Repeat Password"
              onChange={formik.handleChange}
              value={formik.values.passwordRep}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.passwordRep}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="firstName"
            isInvalid={formik.touched.firstName && formik.errors.firstName}
            mb={4}
          >
            <Input
              id="firstName"
              name="firstName"
              type="text"
              placeholder="First Name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.firstName}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="lastName"
            isInvalid={formik.touched.lastName && formik.errors.lastName}
            mb={4}
          >
            <Input
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Last Name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.lastName}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="email"
            isInvalid={formik.touched.email && formik.errors.email}
            mb={4}
          >
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Email@EmailProvider.com"
              onChange={formik.handleChange}
              value={formik.values.email}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.email}
            </FormErrorMessage>
          </FormControl>
          <Button type="submit" colorScheme="teal" width="100%" mt={4}>
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
}
