import { useState, useEffect } from "react";
import { useApi } from "../contexts/ApiProvider";
import { PortfolioWidget } from "../components/PortfolioWidget";
import {
  Flex,
  IconButton,
  SimpleGrid,
  Container,
  Center,
  Text,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export function Portfolios() {
  const [portfolios, setPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const api = useApi();

  useEffect(() => {
    (async () => {
      const response = await api.get("/portfolios");
      console.log("response", response);
      if (response.ok) {
        setPortfolios(response.body);
        setIsLoading(false);
      } else {
        // Handle error
      }
    })();
  }, [api]);

  return (
    <>
      <Container maxW="md" py={40} flexGrow="1">
        <Text
          as="h2"
          fontSize="2xl"
          fontWeight="bold"
          mb={4}
          textAlign="center"
          color="#333"
        >
          Portfolios
        </Text>
        <Center>
          {isLoading ? (
            <Flex flexDirection="column" alignItems="center">
              Loading...
            </Flex>
          ) : (
            <SimpleGrid
              marginTop="50px"
              width="100%"
              minChildWidth="180px"
              spacing="40px"
            >
              {portfolios.map((portfolio) => (
                <PortfolioWidget portfolio={portfolio} key={portfolio.id} />
              ))}
              <Link to="/portfolio/create" position="relative">
                <IconButton
                  size="lg"
                  top="75%"
                  //margin="auto 0"
                  width="50px"
                  height="50px"
                  colorScheme="teal"
                  //aria-label="Create Portfolio"
                  icon={<FaPlus />}
                />
              </Link>
            </SimpleGrid>
          )}
        </Center>
      </Container>
    </>
  );
}
