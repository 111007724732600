// import { extendTheme } from "@chakra-ui/react";

// const theme = extendTheme({
//   config: {
//     initialColorMode: "light",
//     useSystemColorMode: false,
//   },
//   styles: {
//     global: {
//       body: {
//         backgroundColor: "#f5f5f5",
//       },
//       ".form-box": {
//         backgroundColor: "#fff",
//         boxShadow: "md",
//         borderRadius: "md",
//       },
//     },
//   },

//   fonts: {
//     heading: "Arial, sans-serif",
//     body: "Arial, sans-serif",
//     // color: "#333",
//   },
//   colors: {
//     primary: {
//       50: "#e5f6f6",
//       100: "#c2e7e7",
//       // Add more shades of primary color if needed
//     },
//     secondary: {
//       50: "#fff4e5",
//       100: "#ffe2b8",
//       // Add more shades of secondary color if needed
//     },
//     // Add more custom colors as required
//   },
//   components: {
//     Heading: {
//       baseStyle: {
//         color: "#333",
//       },
//     },
//     Text: {
//       baseStyle: {
//         color: "#555",
//       },
//     },
//     Input: {
//       baseStyle: {
//         bg: "#f5f5f5",
//         _placeholder: {
//           color: "#999",
//         },
//         color: "#333",
//         focusBorderColor: "teal.500",
//       },
//     },
//     FormControl: {
//       baseStyle: {
//         mb: 4,
//       },
//     },
//     FormErrorMessage: {
//       baseStyle: {
//         color: "red.500",
//       },
//     },
//     Button: {
//       baseStyle: {
//         fontWeight: "bold",
//         borderRadius: "md",
//       },
//       variants: {
//         primary: {
//           bg: "primary.500",
//           color: "white",
//           _hover: {
//             bg: "primary.600",
//           },
//         },
//         secondary: {
//           bg: "secondary.500",
//           color: "white",
//           _hover: {
//             bg: "secondary.600",
//           },
//         },
//       },
//     },
//     //define the box component
//     box: {
//       baseStyle: {
//         border: "1px solid",
//         borderColor: "gray.200",
//         borderRadius: "md",
//         boxShadow: "md",
//         backgroundColor: "#fff",
//       },
//     },

//     // Customize other components as required
//   },
// });

// export default theme;

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        backgroundColor: "#f5f5f5",
        color: "#333",
      },
    },
  },
  fonts: {
    heading: "Arial, sans-serif",
    body: "Arial, sans-serif",
  },
  colors: {
    primary: {
      50: "#e5f6f6",
      100: "#c2e7e7",
    },
    secondary: {
      50: "#fff4e5",
      100: "#ffe2b8",

      //     // Add more custom colors as required
    },
    components: {
      Button: {
        baseStyle: {
          borderRadius: "full",
          width: "100%",
          mt: 4,
        },
        variants: {
          teal: {
            bg: "teal.400",
            color: "white",
            _hover: {
              bg: "teal.600",
            },
          },
        },
      },
      Input: {
        baseStyle: {
          bg: "#f5f5f5",
          _placeholder: {
            color: "#999",
          },
          color: "#333",
          focusBorderColor: "teal.500",
        },
      },
      Heading: {
        baseStyle: {
          fontSize: "2xl",
          fontWeight: "bold",
          mb: 4,
          textAlign: "center",
          color: "#333",
        },
      },
      Box: {
        baseStyle: {
          bg: "#fff",
          p: 8,
          boxShadow: "md",
          borderRadius: "md",
        },
      },
    },
  },
});

export default theme;
