import GoogleMapReact from 'google-map-react';
import { Box } from "@chakra-ui/react";
import { IoLocationSharp } from 'react-icons/io5';
import { BsBuildingsFill } from 'react-icons/bs';
import {useState, useRef} from 'react';

import { forwardRef } from 'react';

const Marker = forwardRef((props, ref) => {
    let lat = 2.0
    let lng = 3.0

    return <BsBuildingsFill
        color="red"
        lat={lat}
        lng={lng}
        fontSize="30px"
        text="My Marker"
    />
});


//export function MapContainer({coordinates}) {
const MapContainer = forwardRef(function MapContainer(props, ref) {
    const { label, ...otherProps } = props;

    const coordinates = [
        {
            lat: 47.41479910021686,
            lng: 8.668228141874227
        }
    ]
    const defaultProps = {
            center: {
                lat: 47.41479910021686,
                lng: 8.668228141874227
            },
            zoom: 11
        };

        
    return (
        <Box height="100%" width="100%">
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBGZJbqbaokie6irvz30BNHSUAFbYUUvOE" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                {
                  coordinates.map((coords, index) => (
                    <Marker props={coords} key={index} />
                  ))
                }
            </GoogleMapReact>
        </Box>
    );
});

export { MapContainer }