import { useLocation } from "react-router-dom";
import { MapContainer } from "../components/MapContainer";
import { BsMap, BsList, BsCardChecklist } from "react-icons/bs";
import FileManager from "../components/FileManager";
import {
  Box,
  Text,
  Tab,
  Divider,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  Spacer,
  SimpleGrid,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsHouseFill } from "react-icons/bs";
import { useApi } from "../contexts/ApiProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function AssetDetail() {
  const { id } = useParams();
  const [assets, setAssets] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const [coordinates, setCoordinates] = useState([]);
  const [files, setFiles] = useState([]);

  const asset = id;
  const api = useApi();

  useEffect(() => {
    // Fetch listings from the backend
    fetchAssets();

    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const response = await api.get(`/fileManager/${id}`);

    if (response.ok == false) {
      throw new Error("Failed to fetch files");
    }

    const files = response.body;
    console.log(files);

    setFiles(files);
  };

  const fetchAssets = async () => {
    try {
      const response = await api.get(`/assets/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch assets");
      }
      console.log("response", response);
      const data = await response.body;

      setAssets(data);
      console.log("data", data);

      setCoordinates({
        lat: data.latitude,
        log: data.longitude,
      });
      console.log("coordinates", coordinates);
    } catch (error) {
      console.error(error);
    }
  };

  const generateAssetInfo = (data) => {
    return Object.keys(data).map((key) => (
      <VStack align="start" key={key} spacing={1}>
        <Text fontWeight="semibold" fontSize="sm" color="gray.600">
          {key.toUpperCase().replaceAll("_", " ")}
          {/* 
          {key[0].toUpperCase() + key.slice(1).toLowerCase().replaceAll("_", " ")} */}
        </Text>
        <Text>
          {JSON.stringify(data[key]).replace(/" "/g, "-").replace(/"/g, " ")}
        </Text>
      </VStack>
    ));
  };

  return (
    <Flex
      flexDirection="column"
      flexGrow="1"
      p={65}
      py={40}
      // position={"relative"}
      fontSize="sm"
    >
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        {assets.street} Asset
      </Heading>
      <Divider mb={6} />

      <SimpleGrid columns={{ base: 1, md: 2 }} gap={6}>
        {/* LEFT COLUMN - MAP */}
        <Box
          height="400px"
          borderRadius="md"
          overflow="hidden"
          boxShadow="base"
          p="3"
        >
          <MapContainer coordinates={coordinates} />
        </Box>

        {/* RIGHT COLUMN - ASSETS */}
        <Box
          borderRadius="md"
          boxShadow="base"
          p="4"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              borderRadius: "xl",
              backgroundColor: "gray.200",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "xl",
              backgroundColor: "gray",
            },
          }}
        >
          {/* <Flex align="center" mb="4"> */}
          {/* <Heading size="md" flex="1">
              {assets.street} Asset
            </Heading> */}
          {/* <Box as={BsHouseFill} color="teal.500" w={8} h={8} mr={2} /> */}
          {/* </Flex> */}

          <Tabs
            isLazy
            index={selectedTab}
            onChange={handleTabChange}
            colorScheme="teal"
          >
            <TabList mb="2">
              <Tab _hover={{ color: "teal.500", fontWeight: "semibold" }}>
                <Box as={BsMap} w={5} h={5} mr={2} />
                Information
              </Tab>
              <Tab _hover={{ color: "teal.500", fontWeight: "semibold" }}>
                <Box as={BsList} w={5} h={5} mr={2} />
                Listings
              </Tab>
              <Tab _hover={{ color: "teal.500", fontWeight: "semibold" }}>
                <Box as={BsCardChecklist} w={5} h={5} mr={2} />
                All Data
              </Tab>
              <Tab _hover={{ color: "teal.500", fontWeight: "semibold" }}>
                <Box as={BsCardChecklist} w={5} h={5} mr={2} />
                Files Upload
              </Tab>
              <Tab _hover={{ color: "teal.500", fontWeight: "semibold" }}>
                <Box as={BsCardChecklist} w={5} h={5} mr={2} />
                Files View
              </Tab>
            </TabList>

            <TabPanels
              maxH="300px"
              overflowY="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  borderRadius: "xl",
                  backgroundColor: "gray.200",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "xl",
                  backgroundColor: "gray",
                },
              }}
            >
              <TabPanel>
                <SimpleGrid columns={2} spacing={4}>
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                      Asset Address:
                    </Text>
                    <Text fontSize="sm">
                      {assets.street} {assets.street_nr}
                    </Text>
                  </VStack>

                  <VStack align="start" spacing={1}>
                    <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                      Kantonskuerzel:
                    </Text>
                    <Text> {assets.kantonskuerzel}</Text>
                  </VStack>

                  <VStack align="start" spacing={1}>
                    <Text fontWeight="semibold" fontSize="sm" color="gray.600">
                      Gemeindename:
                    </Text>
                    <Text> {assets.gemeindename}</Text>
                  </VStack>
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <Text>
                  {asset.hasListings
                    ? "There are listings for this asset"
                    : "No listings available"}
                </Text>
              </TabPanel>
              <TabPanel>
                <VStack align="start" spacing={4}>
                  {generateAssetInfo(assets)}
                </VStack>
              </TabPanel>
              {/* Content for Tab 4 */}
              {/* Example content */}
              <TabPanel>
                <FileManager
                  fetchFiles={fetchFiles}
                  meta_data={{ "asset_id": id }} />
              </TabPanel>

              {/* Content for Tab 5 */}
              {/* Example content */}
              <TabPanel>
                Your uploaded files
                <ul>
                  {files.map((file, index) => {
                    return (<li key={index}><a href={file.url}>{file.filename}</a></li>)
                  })}
                </ul>
              </TabPanel>
            </TabPanels >
          </Tabs>
        </Box>
      </SimpleGrid>
    </Flex>
  );
}
