//import "./App.css";
import { ChakraProvider, Box, extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Footer from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { SignUp } from "./pages/SignUp";
import { Login } from "./pages/Login";
import { SearchListings } from "./pages/SearchListings";
import { SearchAssets } from "./pages/SearchAssets";
import { Results } from "./pages/Results";
import { ResultsAssets } from "./pages/ResultsAssets";
import { Portfolios } from "./pages/Portfolios";
import { CreatePortfolio } from "./pages/CreatePortfolio";
import { PortfolioDetail } from "./pages/PortfolioDetail";
import { AssetDetail } from "./pages/AssetDetail";
import UserProvider from "./contexts/UserProvider";
import ApiProvider from "./contexts/ApiProvider";
import theme from "./styles/theme";

//import ReactGA from "react-ga4";

//ReactGA.initialize("G-9FMB8DG8BS");

function Layout() {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box flexGrow={1}>
        {" "}
        {/* Use flexGrow to make this section fill the remaining space */}
        <Navbar />
        <Outlet />
      </Box>
      <Footer textAlign="center" />
    </Box>
  );
}

function App() {
  // Define routes
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "search/listings",
          element: <SearchListings />,
        },
        {
          path: "results",
          element: <Results />,
        },
        {
          path: "search/assets",
          element: <SearchAssets />,
        },
        {
          path: "results/assets",
          element: <ResultsAssets />,
        },
        {
          path: "/assets/:id",
          element: <AssetDetail />,
        },
        {
          path: "portfolios",
          element: <Portfolios />,
        },
        {
          path: "portfolio/create",
          element: <CreatePortfolio />,
        },
        {
          path: "portfolio/detail/:id",
          element: <PortfolioDetail />,
        },
      ],
    },
  ]);

  return (
    <ChakraProvider theme={theme}>
      <ApiProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </ApiProvider>
    </ChakraProvider>
  );
}

export default App;
