import React, { useEffect } from 'react';
import { Uppy, debugLogger } from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload'

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

// Don’t forget to keep the Uppy instance outside of your component.
const uppy = new Uppy({ logger: debugLogger, debug: true, autoProceed: false })
    .use(XHRUpload, {
        endpoint: process.env.REACT_APP_API_URL + '/api/fileManager/upload',
        fieldName: 'file',
        formData: true,
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('accessToken')
        }
    })


function FileManager({ fetchFiles, meta_data = null }) {
    // Add meta data to the request
    useEffect(() => {
        if (meta_data != null) {
            uppy.on('file-added', (file) => {
                uppy.setFileMeta(file.id, meta_data);
            });
        }
    }, [uppy]);

    useEffect(() => {
        // If the upload of a file was successfull, we call the parents
        // fetchFiles function to update the list of files.
        uppy.on('upload-success', (file, response) => {
            fetchFiles();
        });
    }, [uppy]);

    return (
        <div style={{ height: "400px", widthborder: "1px solid red" }}>
            <Dashboard inline={true} width="100%" height="100%" uppy={uppy} />
        </div>
    )
}

export default FileManager;