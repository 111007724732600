import { createContext, useContext } from 'react';
import ModuluApiClient from '../ModuluApiClient';

const ApiContext = createContext();

export default function ApiProvider({ children }) {
    const api = new ModuluApiClient(process.env.REACT_APP_API_URL);
    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export function useApi() {
    return useContext(ApiContext);
}