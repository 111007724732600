import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import {
  Flex,
  Button,
  Input,
  Container,
  Heading,
  Box,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

export function Login() {
  const { login } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.username) {
        errors.username = "Username is required";
      }

      if (!values.password) {
        errors.password = "Password is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const username = formik.values.username;
      const password = formik.values.password;

      const result = await login(username, password);
      if (result === "fail") {
        // TODO: flash message or error message
      } else if (result === "ok") {
        let next = "/";
        if (false) {
          //(location.state && location.state.next) {
          next = location.state.next;
        }
        navigate(next);
      }
    },
  });

  return (
    <Container maxW="md" py={"18vh"} position={"relative"}>
      <Box bg="#fff" p={8} boxShadow="md" borderRadius="md">
        <Heading as="h2" size="lg" mb={6} textAlign="center" color="#333">
          Sign in
        </Heading>

        <form onSubmit={formik.handleSubmit}>
          <FormControl
            id="username"
            isInvalid={formik.touched.username && formik.errors.username}
            mb={4}
          >
            <FormLabel htmlFor="username" color="#333">
              Username
            </FormLabel>
            <Input
              id="username"
              name="username"
              type="text"
              _placeholder={{ color: "#999" }}
              onChange={formik.handleChange}
              value={formik.values.username}
              bg="#f5f5f5"
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.username}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="password"
            isInvalid={formik.touched.password && formik.errors.password}
            mb={6}
          >
            <FormLabel htmlFor="password" color="#333">
              Password
            </FormLabel>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              bg="#f5f5f5"
              _placeholder={{ color: "#999" }}
              color="#333"
              focusBorderColor="teal.500"
            />
            <FormErrorMessage color="red.500">
              {formik.errors.password}
            </FormErrorMessage>
          </FormControl>

          <Button width="100%" type="submit" colorScheme="teal">
            Submit
          </Button>
        </form>

        <Text mt={4} textAlign="center" color="#555">
          Don't have an account?{" "}
          <Link to="/signup" color="teal.500">
            Sign up here
          </Link>
        </Text>
      </Box>
    </Container>
  );
}
