import { Link } from "react-router-dom";
import {
  Text,
  Box,
  Flex,
  Button,
  Heading,
  Image,
  Grid,
  Container,
} from "@chakra-ui/react";
import { useUser } from "../contexts/UserProvider";

export function Home() {
  const { user } = useUser();

  return (
    <Box minHeight="100vh">
      <Grid
        templateColumns={{ base: "100%", md: "50% 50%" }}
        alignItems="center"
        bg="gray.200"
      >
        <Box w="100%" h="full">
          <Image
            src="modulu.png"
            alt="Hero"
            objectFit="cover"
            w="100%"
            h="100%"
          />
        </Box>
        <Box p={{ base: 6, md: 12 }}>
          <Heading
            fontSize={{ base: "4xl", md: "6xl" }}
            // fontWeight="bold"
            // color="#333"
            mb={6}
            // lineHeight="1.2"
            // fontFamily="Arial, sans-serif"
          >
            {user
              ? `Hello ${user.username}!`
              : `We do Real Estate.
              Online`}
          </Heading>

          {!user && (
            <Link to="/signup">
              <Button
                size="lg"
                bg="teal.400"
                borderRadius="full"
                boxShadow="md"
                px={10}
                py={6}
                _hover={{
                  bg: "teal.600",
                }}
              >
                Join us!
              </Button>
            </Link>
          )}
        </Box>
      </Grid>

      {!user && (
        <Box bg="#f5f5f5" py={20}>
          <Container maxW="container.xl">
            <Grid
              templateColumns={{ base: "100%", md: "1fr 1fr" }}
              gap={{ base: 6, md: 10 }}
              alignItems="center"
            >
              <Box>
                <Image
                  src="picture.png"
                  alt="Welcome Image"
                  maxH={{ base: "300px", md: "400px" }}
                  objectFit="cover"
                  borderRadius="lg"
                  boxShadow="lg"
                />
              </Box>

              <Box>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color="#333"
                  mb={4}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Welcome to Our Real Estate Services
                </Heading>
                <Text fontSize={{ base: "xl", md: "2xl" }} color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  gravida eros vel pulvinar eleifend. Nunc dapibus hendrerit
                  sapien non fringilla. Curabitur non nibh ut ipsum finibus
                  tristique.
                </Text>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}

      {!user && (
        <Box bg="#f5f5f5" py={20}>
          <Container maxW="container.xl">
            <Grid
              templateColumns={{ base: "100%", md: "1fr 1fr" }}
              gap={{ base: 6, md: 10 }}
              alignItems="center"
            >
              <Box order={{ base: 2, md: 1 }}>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color="#333"
                  mb={4}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Our Expert Team
                </Heading>
                <Text fontSize={{ base: "xl", md: "2xl" }} color="#555">
                  Vestibulum a magna tristique, lobortis nisl nec, tempus
                  tortor. Aliquam nec urna nec turpis efficitur pharetra. Nam
                  nec nunc tempor, dapibus ligula sit amet, ultricies dui.
                </Text>
              </Box>
              <Box order={{ base: 1, md: 2 }}>
                <Image
                  src="picture.png"
                  alt="Expert Team Image"
                  maxH={{ base: "300px", md: "400px" }}
                  objectFit="cover"
                  borderRadius="lg"
                  boxShadow="lg"
                />
              </Box>
            </Grid>
          </Container>
        </Box>
      )}

      {!user && (
        <Box bg="#333" py={20} color="white">
          <Container maxW="container.xl">
            <Text
              textAlign="center"
              fontSize="3xl"
              fontWeight="bold"
              mb={10}
              textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
            >
              Featured Properties
            </Text>
            <Grid
              templateColumns={{ base: "100%", md: "repeat(3, 1fr)" }}
              gap={8}
            >
              <Box
                p={4}
                mb={8}
                cursor="pointer"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
                borderRadius="lg"
                bg="white"
                boxShadow="lg"
              >
                <Image
                  src="picture.png"
                  alt="Property 1"
                  borderRadius="lg"
                  mb={4}
                />
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="#333"
                  mb={2}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Property 1
                </Text>
                <Text fontSize="xl" color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box>
              <Box
                p={4}
                mb={8}
                cursor="pointer"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
                borderRadius="lg"
                bg="white"
                boxShadow="lg"
              >
                <Image
                  src="picture.png"
                  alt="Property 2"
                  borderRadius="lg"
                  mb={4}
                />
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="#333"
                  mb={2}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Property 2
                </Text>
                <Text fontSize="xl" color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box>
              <Box
                p={4}
                mb={8}
                cursor="pointer"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
                borderRadius="lg"
                bg="white"
                boxShadow="lg"
              >
                <Image
                  src="picture.png"
                  alt="Property 3"
                  borderRadius="lg"
                  mb={4}
                />
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="#333"
                  mb={2}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Property 3
                </Text>
                <Text fontSize="xl" color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}

      {!user && (
        <Box bg="#f5f5f5" py={20}>
          <Container maxW="container.xl">
            <Grid
              templateColumns={{ base: "100%", md: "1fr 1fr" }}
              gap={{ base: 6, md: 10 }}
              alignItems="center"
            >
              <Box>
                <Image
                  src="picture.png"
                  alt="Section 1 Image"
                  maxH={{ base: "300px", md: "400px" }}
                  objectFit="cover"
                  borderRadius="lg"
                  boxShadow="lg"
                />
              </Box>
              <Box>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color="#333"
                  mb={4}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Section 1
                </Heading>
                <Text fontSize={{ base: "xl", md: "2xl" }} color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  gravida eros vel pulvinar eleifend. Nunc dapibus hendrerit
                  sapien non fringilla. Curabitur non nibh ut ipsum finibus
                  tristique.
                </Text>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
      {!user && (
        <Box bg="#fff" py={20}>
          <Container maxW="container.xl">
            <Grid
              templateColumns={{ base: "100%", md: "1fr 1fr" }}
              gap={{ base: 6, md: 10 }}
              alignItems="center"
            >
              <Box order={{ base: 2, md: 1 }}>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color="#333"
                  mb={4}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Section 2
                </Heading>
                <Text fontSize={{ base: "xl", md: "2xl" }} color="#555">
                  Vestibulum a magna tristique, lobortis nisl nec, tempus
                  tortor. Aliquam nec urna nec turpis efficitur pharetra. Nam
                  nec nunc tempor, dapibus ligula sit amet, ultricies dui.
                </Text>
              </Box>

              <Box order={{ base: 1, md: 2 }}>
                <Image
                  src="picture.png"
                  alt="Section 2 Image"
                  maxH={{ base: "300px", md: "400px" }}
                  objectFit="cover"
                  borderRadius="lg"
                  boxShadow="lg"
                />
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
      {!user && (
        <Box bg="#f5f5f5" py={20}>
          <Container maxW="container.xl">
            <Grid
              templateColumns={{ base: "100%", md: "1fr 1fr" }}
              gap={{ base: 6, md: 10 }}
              alignItems="center"
            >
              <Box>
                <Image
                  src="picture.png"
                  alt="Section 3 Image"
                  maxH={{ base: "300px", md: "400px" }}
                  objectFit="cover"
                  borderRadius="lg"
                  boxShadow="lg"
                />
              </Box>

              <Box>
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="bold"
                  color="#333"
                  mb={4}
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                >
                  Section 3
                </Heading>
                <Text fontSize={{ base: "xl", md: "2xl" }} color="#555">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  gravida eros vel pulvinar eleifend. Nunc dapibus hendrerit
                  sapien non fringilla. Curabitur non nibh ut ipsum finibus
                  tristique.
                </Text>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
}
