import { Link } from "react-router-dom";
import { Box, Image, Text, Avatar } from "@chakra-ui/react";
import {
  Card,
  Button,
  Stack,
  CardBody,
  Heading,
  CardFooter,
} from "@chakra-ui/react";

export function Property({
  property: {
    ImageUrls,
    PriceText,
    NumRooms,
    Title,
    Area,
    ShowPremiumListingPromotion,
  },
  Details,
}) {
  const defaultImage =
    "https://www.kindpng.com/picc/m/271-2712699_house-cartoon-png-go-home-cartoon-png-transparent.png";

  return (
    <Box py={4} w="100%">
      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        bg="white"
        boxShadow="md"
        borderRadius="md"
      >
        <Image
          height={{ base: "180px", sm: "200px" }}
          width={{ base: "100%", sm: "200px" }}
          objectFit="cover"
          src={
            ImageUrls ? (ImageUrls[0] ? ImageUrls[0] : ImageUrls) : defaultImage
          }
          alt="Property Image"
          borderRadius="md"
        />

        <Stack px={4} py={2} flex="1" spacing={2}>
          <CardBody>
            <Heading as="h2" size="lg" fontWeight="bold" noOfLines={2}>
              {Title}
            </Heading>
            <Text fontSize="sm" color="gray.600" noOfLines={3}>
              Caffè latte is a coffee beverage of Italian origin made with
              espresso and steamed milk.
            </Text>
          </CardBody>

          <CardFooter justifyContent="space-between" alignItems="center">
            {ShowPremiumListingPromotion && (
              <Text fontSize="sm" color="green.400" fontWeight="bold">
                ✅ Premium Listing
              </Text>
            )}
            <Box>
              <Text fontSize="lg" fontWeight="bold" color="teal.600">
                {PriceText}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {NumRooms &&
                  `${NumRooms} ${NumRooms === 1 ? "room" : "rooms"} • `}
                {Area && `${Area} m²`}
              </Text>
            </Box>
            <Link to={`/${Details}`}>
              <Button
                variant="solid"
                colorScheme="teal"
                size="sm"
                px={4}
                py={2}
                fontWeight="bold"
              >
                View Details
              </Button>
            </Link>
          </CardFooter>
        </Stack>
      </Card>
    </Box>
  );
}
