import { useState, useEffect } from "react";

import { Flex, Text, Box, Container} from "@chakra-ui/react";

import { useApi } from "../contexts/ApiProvider";

import { Property } from "../components/Property";

import { Paginate } from "../components/Pagination";

import { useLocation } from "react-router-dom";

export function Results() {
  const api = useApi();

  const location = new URLSearchParams(useLocation().search);

  const limit = 6;
  const [page, setPage] = useState(0);

  const [results, setResults] = useState({
    nbHits: 0,
    hits: Array(limit).fill("Loading"),
  });
  const [isLoading, setIsLoading] = useState(true);

  const zipcode = location.get("zipCode");

  useEffect(() => {
    const offSet = page * limit;
    (async () => {
      const response = await api.get(
        `/listings/zip_code/${zipcode}?limit=${limit}&offset=${offSet}`
      );
      if (response.ok) {
        // Extract the 'data' array from the response body
        const { data, pagination } = response.body;
        setResults({ nbHits: pagination.total, hits: data });
        setIsLoading(false);
      }
    })();
  }, [api, page, zipcode]);

  return isLoading ? (
    <Flex flexDirection="column" alignItems="center" py={40}>
      Loading...
    </Flex>
  ) : (
    <>
      <Container flexGrow="1" maxW="container.lg" py={40}>
        <Text color="#333" fontSize="calc(10px + 2vmin)">
          The number of results is {results.nbHits}.
        </Text>

        <Flex flexDirection="column" alignItems="center">
          {results.hits.map((property) => (
            <Property
              marginBottom="10px"
              key={property.AdId}
              property={property}
            />
          ))}
        </Flex>

        {/* <Pagination nbHits={results.nbHits} offSet={offSet} limit={limit}/> */}
        <Box padding="0px 50px">
          <Paginate
            // required props 👇
            page={page}
            count={results.nbHits}
            pageSize={limit}
            onPageChange={setPage} // function that take page pressed as an argument
            // optional props 👇
            margin={2}
            variant="outline"
            // ...border and other props also work 💪
            border="2px solid"
            // you can use w to adjust to parent
            // container
            w="full"
          />
        </Box>
        <Box marginBottom="50px" />
      </Container>
    </>
  );
}
